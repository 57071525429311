import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../context/AuthContext';
import a1 from '../assets/img/about1.jpeg';
import a2 from '../assets/img/about2.jpeg';
import a3 from '../assets/img/about3.jpeg';
import a4 from '../assets/img/about4.jpeg';

const About = () => {
  const { setPageTitle } = useContext(AuthContext);
  const [idx, setIdx] = useState([111, 222, 333]);

  useEffect(() => {
    setPageTitle('About Us');
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='about'>
      <section>
        <div className='container text-justify'>
          <div className='row mb-3'>
            <div className='col-12'>
              <h3 className='title mb-3'>ABOUT US</h3>
              <section>
                <p style={{fontStyle: 'italic' }}>AmarLab brings diagnostics at your doorstep.</p>
                <img src={a1} style={{marginBottom: '20px', marginTop: '10px' }}  alt='co-founder' width='600px' className='web about-img' />
                <p>
                  We have reimagined and redesigned diagnostic experience for you, by collecting samples for tests from the comfort of their homes and known environment. No more long waits at clinics or labs, no more stressful commutes – just accurate, reliable, and hassle-free diagnostic services in the comfort of your home.
                </p>
              </section>
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-12'>
              <h4 className='subtitle mb-3'>OUR MISSION</h4>
              <section>
                <p style={{fontStyle: 'italic' }}>Making healthcare easily accessible to everyone, everywhere.</p>
                <img src={a2} style={{ marginBottom: '20px', marginTop: '10px' }}  alt='co-founder' width='600px' className='web about-img' />
                <p>
                  At AmarLab, we make healthcare simple, stress-free, and accessible by bringing reliable at-home diagnostic services to everyone in Bangladesh.
                </p>
                <p>
                  Did you know 50% of prescribed diagnostic tests go unfulfilled? With 81% of the population lacking access to basic diagnostic services, patients waste an average of 5.5 traveling to clinics, waiting for tests, and collecting reports – leading to delayed diagnoses and unnecessary suffering.
                </p>
                <p>
                  We are changing that. Our certified phlebotomists collect samples from your home at your convenience, ensuring fast, accurate results – saving you time, money, and stress. Whether managing chronic conditions or prioritizing wellness, we make healthcare work for you.
                </p>
              </section>
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-12'>
              <h4 className='subtitle mb-3'>OUR FOUNDING STORY</h4>
              <section>
                <p style={{fontStyle: 'italic' }}>How AmarLab was brought to light</p>
                <img src={a3} style={{ marginBottom: '20px', marginTop: '10px' }}  alt='co-founder' width='600px' className='web about-img' />
                <p>
                  In 2007, Tazin Shadid’s world changed when his mother was diagnosed with late-stage cancer. While working at Microsoft, he struggled to navigate Bangladesh’s healthcare system to get her the right treatment. Though she survived, the experience left a lasting impact, sparking his mission to transform healthcare accessibility.
                </p>

                <p>That same year, Tazin founded a free clinic to provide consultations and diagnostic services, with Dr. Ishtiaque Zahid as the first doctor on board. Over the next decade, they served over 200,000 patients, gaining firsthand insights into the challenges of healthcare delivery.</p>
                <p>When Tazin returned to Bangladesh in 2016, he saw an urgent need for practical, patient-friendly solutions. Long wait times, limited access to specialists, and the hassle of diagnostic testing made quality healthcare difficult for many. Together with Dr. Zahid and their close friend and fellow serial entrepreneur Sabbir Amin, they set out to bridge this gap with a groundbreaking idea – at-home diagnostic testing.</p>
                <p>After a year of R&D, developing specialized sample collection kits, refining processes, and training staff, AmarLab was born. Initially led by Tamzid Siddiq Spondon, the team built the MVP and validated the model before Tazin and Dr. Zahid took over full-time.</p>
                <p>Today, AmarLab is revolutionizing healthcare in Bangladesh, making diagnostics simple, convenient, and accessible – one home at a time.</p>

              </section>
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-12'>
              <h4 className='subtitle mb-3'>OUR CO-FOUNDERS</h4>
              <section>
                <p style={{fontStyle: 'italic' }}>A team of healthcare innovators and experienced professionals</p>
                <img src={a4} style={{ marginBottom: '20px', marginTop: '10px' }}  alt='co-founder' width='600' className='web about-img' />
                <h5 className='mb-2'>Tazin Shadid</h5>
                <p style={{fontStyle: 'italic'}}>Co-founder & CEO</p>
                <p>
                  Tazin is a health-tech entrepreneur with over 20 years of experience in designing and building innovative products and services that make healthcare easily accessible to everyone, everywhere. Under his leadership, AmarLab has raised seed capital from local and global investors and grown revenue by 500% in the last year. In addition, he is the founder of Spreeha, a non-profit that integrates human-centered design to create and deliver sustainable solutions in healthcare and emergency disaster response, serving more than half a million people to date.
                </p>
                <p>
                  Before returning to Bangladesh, Tazin was a product innovation leader at Microsoft Headquarters in Seattle for over 10 years.
                </p>
                <h5 style={{ marginTop: '30px' }}  className='mb-2'>Dr. Ishtique Zahid</h5>
                <p style={{fontStyle: 'italic'}}>Co-founder & COO</p>
                <p>
                  Zahid is a physician and entrepreneur on a mission to make healthcare accessible to everyone, everywhere. His operational expertise has enabled AmarLab to revolutionize health diagnostics – making it as effortless as ordering food from home. With over 17 years of experience in the healthcare field, Zahid has contributed across various medical disciplines, from emergency care to chronic disease management. He has designed and implemented multiple public health initiatives, including telehealth, community clinics, and health education programs. Zahid further enhanced his expertise by earning a master’s degree in hospital and healthcare management. He is also leading a tech-enabled hyperlocal urgent healthcare network at Spreeha as Senior Technical Advisor.
                </p>
              </section>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
