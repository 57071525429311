import { useContext } from 'react';
import FI1 from '../../assets/img/fi1.png';
import FI2 from '../../assets/img/fi2.svg';
import FI3 from '../../assets/img/fi3.png';
import FI4 from '../../assets/img/fi4.svg';
import FI5 from '../../assets/img/fi5.png';
import FI6 from '../../assets/img/fi6.png';

import SL1 from '../../assets/img/support1.jpg';
import SL2 from '../../assets/img/support2.jpg';
import SL3 from '../../assets/img/spreeha.jpg';
import SL4 from '../../assets/img/support4.jpeg';

import { AuthContext } from '../../context/AuthContext';

const FeaturedIn = () => {
  const { postActivity } = useContext(AuthContext);
  return (
    <section className='sponsor featured'>
    <div className='container support-by'>
      <div className='row'>
        <div className='col-12'>
          <h5 className='section-heading text-center'>Supported By</h5>
        </div>
        <div
          className='col-12'
          onClick={() =>
            postActivity('box', 'Featured In Logo', window.location.href)
          }
        >
          <div className='images desktop-d'>
            <img src={SL1} title='Accelerating Asia' />
            <img src={SL2} title='Startup Bangladesh,' className='s-b' />
            <img src={SL3} title='Spreeha' className='s-p'/>
            <img src={SL4} title='IIX' />
          </div>
          <marquee className='images mobile-d'>
            <img src={SL1} title='Accelerating Asia' />
            <img src={SL2} title='Startup Bangladesh,' className='s-b' />
            <img src={SL3} title='Spreeha' className='s-p'/>
            <img src={SL4} title='IIX' />
          </marquee>
        </div>
      </div>
    </div>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h5 className='section-heading text-center'>Featured In</h5>
          </div>
          {/* {[...Array(10)].map((d, i) => (
            <marquee className='images' key={i}>
              <img src={FI1} alt='' />
              <img src={FI2} alt='' className='daily-star' />
              <img src={FI3} alt='' />
              <img src={FI4} alt='' className='dhaka-tribun' />
              <img src={FI5} alt='' />
              <img src={FI6} alt='' className='new-adge' />
            </marquee>
          ))} */}
          <div
            className='col-12'
            onClick={() =>
              postActivity('box', 'Featured In Logo', window.location.href)
            }
          >
            <marquee className='images'>
              <img src={FI1} alt='' />
              <img src={FI2} alt='' className='daily-star' />
              <img src={FI3} alt='' />
              <img src={FI4} alt='' className='dhaka-tribun' />
              <img src={FI5} alt='' />
              <img src={FI6} alt='' className='new-adge' />
              {/* {' '} */}
              <img src={FI1} alt='' />
              <img src={FI2} alt='' className='daily-star' />
              <img src={FI3} alt='' />
              <img src={FI4} alt='' className='dhaka-tribun' />
              <img src={FI5} alt='' />
              <img src={FI6} alt='' className='new-adge' />
              <img src={FI1} alt='' />
              <img src={FI2} alt='' className='daily-star' />
              <img src={FI3} alt='' />
              <img src={FI4} alt='' className='dhaka-tribun' />
              <img src={FI5} alt='' />
              <img src={FI6} alt='' className='new-adge' />
              <img src={FI1} alt='' />
              <img src={FI2} alt='' className='daily-star' />
              <img src={FI3} alt='' />
              <img src={FI4} alt='' className='dhaka-tribun' />
              <img src={FI5} alt='' />
              <img src={FI6} alt='' className='new-adge' />
              <img src={FI1} alt='' />
              <img src={FI2} alt='' className='daily-star' />
              <img src={FI3} alt='' />
              <img src={FI4} alt='' className='dhaka-tribun' />
              <img src={FI5} alt='' />
              <img src={FI6} alt='' className='new-adge' />
              <img src={FI1} alt='' />
              <img src={FI2} alt='' className='daily-star' />
              <img src={FI3} alt='' />
              <img src={FI4} alt='' className='dhaka-tribun' />
              <img src={FI5} alt='' />
              <img src={FI6} alt='' className='new-adge' />
              <img src={FI1} alt='' />
              <img src={FI2} alt='' className='daily-star' />
              <img src={FI3} alt='' />
              <img src={FI4} alt='' className='dhaka-tribun' />
              <img src={FI5} alt='' />
              <img src={FI6} alt='' className='new-adge' />
              <img src={FI1} alt='' />
              <img src={FI2} alt='' className='daily-star' />
              <img src={FI3} alt='' />
              <img src={FI4} alt='' className='dhaka-tribun' />
              <img src={FI5} alt='' />
              <img src={FI6} alt='' className='new-adge' />
              <img src={FI1} alt='' />
              <img src={FI2} alt='' className='daily-star' />
              <img src={FI3} alt='' />
              <img src={FI4} alt='' className='dhaka-tribun' />
              <img src={FI5} alt='' />
              <img src={FI6} alt='' className='new-adge' />
              <img src={FI1} alt='' />
              <img src={FI2} alt='' className='daily-star' />
              <img src={FI3} alt='' />
              <img src={FI4} alt='' className='dhaka-tribun' />
              <img src={FI5} alt='' />
              <img src={FI6} alt='' className='new-adge' />
              <img src={FI1} alt='' />
              <img src={FI2} alt='' className='daily-star' />
              <img src={FI3} alt='' />
              <img src={FI4} alt='' className='dhaka-tribun' />
              <img src={FI5} alt='' />
              <img src={FI6} alt='' className='new-adge' />
              <img src={FI1} alt='' />
              <img src={FI2} alt='' className='daily-star' />
              <img src={FI3} alt='' />
              <img src={FI4} alt='' className='dhaka-tribun' />
              <img src={FI5} alt='' />
              <img src={FI6} alt='' className='new-adge' />
              <img src={FI1} alt='' />
              <img src={FI2} alt='' className='daily-star' />
              <img src={FI3} alt='' />
              <img src={FI4} alt='' className='dhaka-tribun' />
              <img src={FI5} alt='' />
              <img src={FI6} alt='' className='new-adge' />
              <img src={FI1} alt='' />
              <img src={FI2} alt='' className='daily-star' />
              <img src={FI3} alt='' />
              <img src={FI4} alt='' className='dhaka-tribun' />
              <img src={FI5} alt='' />
              <img src={FI6} alt='' className='new-adge' />
            </marquee>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturedIn;
